
// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import { useParams, useNavigate } from 'react-router-dom';
// import CCimage from '../images/CCimage.png';
// import axislogo from '../images/axislogo.png';
// import fileicon from '../images/fileicon.png';
// // import Creditcardicon from '../images/Creditcardicon.png';
// import { FaGlobe } from 'react-icons/fa';
// import '../css/user_details.css';

// const UserDetailsPage = () => {
//     const [userData, setUserData] = useState(null);
//     const [error, setError] = useState('');
//     const { phoneNumber } = useParams();
//     const navigate = useNavigate();

//     useEffect(() => {
//         const fetchUserData = async () => {
//             try {
//                 const response = await axios.get(`http://127.0.0.1:8000/user_details/${phoneNumber}`);
//                 // console.log('Response:', response.data);
//                 setUserData(response.data);
//                 // console.log(response.data)

//             } catch (error) {
//                 console.error('Error fetching user details:', error);
//                 setError('Failed to fetch user details. Please try again later.');
//             }
//         };

//         fetchUserData();

//     }, [phoneNumber]);

//     if (error) {
//         return <div>{error}</div>;
//     }

//     return (
//         <>
//             <div className="page-container">
//                 <div className="top-bar">
//                     <img src={CCimage} alt="logo" className="logo" />
//                     <div className="language-selector">
//                         <FaGlobe className="globe-icon" />
//                         <select className="language-dropdown">
//                             <option value="en">English</option>
//                             <option value="fr">Tamil</option>
//                             <option value="hi">Hindi</option>
//                         </select>
//                     </div>
//                 </div>

//                 {userData && (
//                     <div className="user-info">

//                         <h3>Hi! {userData.name}</h3>
//                         <p>Please find your account information below</p>
//                         {/* {console.log(userData.Product)} */}
//                     </div>
//                 )}

//                 <div className="image-folder">
//                     <img className="axislogo" src={axislogo} alt="bank logo" />
//                     <img className="fileicon" src={fileicon} alt="icon" />
//                     {/* <img className="Creditcardicon" src={Creditcardicon} alt="icon" /> */}

//                 </div>


//                 <div className="content-container1">

//                     {userData ? (
//                         <div>
//                             <div className="tablediv">
//                                 <table >
//                                     <thead>
//                                         <tr>
//                                             <th scope="col"></th>
//                                             <th scope="col"></th>
//                                         </tr>
//                                     </thead>
//                                     <tbody>
//                                         <tr className="blue-row">

//                                             <td>Account Number</td>
//                                             <td>{userData.account_number}</td>
//                                         </tr>

//                                         <tr className="white-row">

//                                             <td>Bank Name</td>
//                                             <td>Axis</td>
//                                         </tr>
//                                         <tr className="blue-row">

//                                             <td>Card Number</td>
//                                             <td>{userData.Card_Number}</td>
//                                         </tr>
//                                         <tr className="white-row">

//                                             <td>Total Outstanding</td>
//                                             <td>{userData.total_outs}</td>
//                                         </tr>
//                                         <tr className="blue-row">

//                                             <td>Product</td>
//                                             <td> {userData.Product}</td>
//                                         </tr>
//                                         <tr className="white-row">

//                                             <td>Account Status</td>
//                                             <td> Write Off</td>
//                                         </tr>
//                                         {/* <tr className="blue-row">

//                                             <td>City</td>
//                                             <td>{userData.city}</td>
//                                         </tr> */}


//                                     </tbody>
//                                 </table>
//                             </div>
//                             {/* <h5 className="blue-row">Account Number:{userData.account_number}</h5>
//                             <h5 className="white-row">Card Number: {userData.card_number}</h5>
//                             <h5 className="blue-row">City: {userData.city}</h5>
//                             <h5 className="white-row">Total Outstanding: {userData.total_outs}</h5>
//                             <h5 className="blue-row">Product: {userData.product}</h5> */}

//                             <p className='lastline'> ⚠ Bank  has initiated legal action</p>
//                             <button type="submit">Pay Full Amount</button>
//                             <button type="submit">Pay in 1 EMIs</button>
//                             <button className="lastbutton" type="submit">Pending for Settlement Approval</button>

//                         </div>
//                     ) : (
//                         <p>Loading...</p>
//                     )}
//                 </div>
//             </div >
//         </>
//     );
// };

// export default UserDetailsPage;






import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import CCimage from '../images/CCimage.png';
import herocorp from '../images/herocorp.png'
// import axislogo from '../images/axislogo.png';
import fileicon from '../images/fileicon.png';
import { FaGlobe } from 'react-icons/fa';
import '../css/user_details.css';

const UserDetailsPage = () => {
    const [userData, setUserData] = useState(null);
    const [error, setError] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [popupContent, setPopupContent] = useState({});
    const { phoneNumber } = useParams();
    const navigate = useNavigate();
    const [showInterestText, setShowInterestText] = useState(false);
    const [showofferText, setshowofferText] = useState(false)
    const [settlementAmount, setSettlementAmount] = useState('');
    const [showSecondPopup, setShowSecondPopup] = useState(false);



    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await axios.get(`https://cd.stefto.com/clearyourdues/user_details/${phoneNumber}`);
                setUserData(response.data);
            } catch (error) {
                console.error('Error fetching user details:', error);
                setError('Failed to fetch user details. Please try again later.');
            }
        };

        fetchUserData();

    }, [phoneNumber]);





    const handlePayNow = () => {
        window.open('https://www.stefto.com/', '_blank');
    };

    const handlePayFullAmount = () => {
        const today = new Date();

        const formattedDate = `${today.getDate().toString().padStart(2, '0')}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getFullYear()}`;

        setPopupContent({
            title: 'Congratulations!',
            emiDetails: [
                { date: formattedDate, amount: userData.total_outs }
            ]

        });

        setshowofferText(true);
        setShowInterestText(false);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        if (popupContent.title === 'Pending for Settlement Approval' && showSecondPopup) {
            setShowModal(false);
            setShowSecondPopup(false);
        } else {

            setShowModal(false);
            setShowSecondPopup(false);
        }
    };


    const handlePayIn1Emis = () => {
        const emiAmount = (userData.total_outs * 0.1).toFixed(2);
        const today = new Date();
        const formattedDate = `${today.getDate().toString().padStart(2, '0')}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getFullYear()}`;
        setPopupContent({
            title: 'Pay in 1 EMIs',
            emiDetails: [
                { date: formattedDate, amount: `${emiAmount}` }
            ]
        });
        setshowofferText(true)
        setShowInterestText(true);
        setShowModal(true);
    };



    const handleSettlementApproval = () => {
        const today = new Date();
        const formattedDate = `${today.getDate().toString().padStart(2, '0')}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getFullYear()}`;

        setPopupContent({
            title: 'Pending for Settlement Approval',
            emiDetails: [
                { date: formattedDate, amount: '' }
            ]
        });
        setshowofferText(false)
        setShowInterestText(true);
        setShowModal(true);
    };


    // const handleCheckForSettlement = () => {
    //     const enteredAmount = parseFloat(settlementAmount);
    //     if (userData && userData.total_outs !== undefined) {
    //         const settlementThreshold = userData.total_outs * 0.7; // 70% of the total outstanding amount
    //         if (!isNaN(enteredAmount)) {
    //             if (enteredAmount >= settlementThreshold) {
    //                 if (enteredAmount > userData.total_outs) {
    //                     console.log('Settlement amount is greater than  total outstanding.');
    //                 }
    //                 else {
    //                     setShowSecondPopup(true);
    //                 }

    //             }
    //             else {

    //                 console.log('Settlement amount should be at least 70% of the total outstanding.');
    //             }
    //         } else {
    //             console.log('Please enter a valid settlement amount.');
    //         }
    //     } else {
    //         console.error('userData or total_outs is not available.');
    //     }
    // };


    const handleCheckForSettlement = () => {
        const enteredAmount = parseFloat(settlementAmount);
        const today = new Date();
        const formattedDate = `${today.getDate().toString().padStart(2, '0')}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getFullYear()}`;

        if (userData && userData.total_outs !== undefined) {
            const settlementThreshold = userData.total_outs * 0.7; // 70% of the total outstanding amount
            if (!isNaN(enteredAmount)) {
                if (enteredAmount >= settlementThreshold) {
                    if (enteredAmount > userData.total_outs) {
                        setPopupContent({
                            title: 'Sorry',
                            message: 'Settlement amount cannot exceed the total outstanding amount.'
                        });
                        setShowModal(true);
                    } else {
                        setPopupContent({
                            title: 'Congratulations!',
                            emiDetails: [
                                { date: formattedDate, amount: settlementAmount }
                            ]
                        });
                        setShowSecondPopup(false);
                    }
                } else {
                    let message = 'Settlement amount should be at least 70% of the total outstanding.';
                    setPopupContent({
                        title: 'Sorry',
                        message: message
                    });
                    setShowModal(true);
                }
            } else {
                console.log('Please enter a valid settlement amount.');
            }
        } else {
            console.error('userData or total_outs is not available.');
        }
    };


    // const showSorryPopup = (enteredAmount, settlementThr eshold) => {
    //     let message = ''; 
    //     if (enteredAmount  < settlementThreshold) {
    //         message = 'Sett lement amount should be at least 70% of the total outstanding.';
    //     } else { 
    //         messa ge = 'Settlement amount cannot exceed the total outstanding amount.';
    //     } 
    //     se tPopupContent({
    //          title: 'Sorry',
    //          message: message
    //     }); 
    //     setS howModal(true);
    // }; 




    if (error) {
        return <div>{error}</div>;
    }

    return (
        <>
            <div className={`page-container ${showModal ? 'modal-open' : ''}`}>
                <div className="top-bar">
                    <img src={CCimage} alt="logo" className="logo" />
                    <div className="language-selector">
                        <FaGlobe className="globe-icon" />
                        <select className="language-dropdown">
                            <option value="en">English</option>
                            <option value="fr">Tamil</option>
                            <option value="hi">Hindi</option>
                        </select>
                    </div>
                </div>

                {userData && (
                    <div className="user-info">
                        <h3>Hi! {userData.name}</h3>
                        <p >Please find your account information below</p>
                    </div>
                )}

                <div className="image-folder">

                    <img className="herocorp" src={herocorp} alt="bank logo" />
                    {/* <h2 className='bank'>Bank</h2> */}

                    <img className="fileicon" src={fileicon} alt="icon" />
                </div>

                <div className="content-container1">
                    {userData ? (
                        <div>
                            <div className="tablediv">
                                <table className='detailtable'>
                                    <thead>
                                        <tr >
                                            <th scope="col"></th>
                                            <th scope="col"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="blue-row">
                                            <td>Account Number</td>
                                            <td>{userData.account_number}</td>
                                        </tr>
                                        <tr className="white-row">
                                            <td>Bank Name</td>
                                            <td>HeroFinCorp</td>
                                            {/* <td>Axis</td> */}
                                        </tr>
                                        <tr className="blue-row">
                                            <td>Card Number</td>
                                            <td>{userData.Card_Number}</td>
                                        </tr>
                                        <tr className="white-row">
                                            <td>Total Outstanding</td>
                                            <td>{userData.total_outs}</td>
                                        </tr>
                                        <tr className="blue-row">
                                            <td>Product</td>
                                            {/* <td> {userData.Product}</td> */}
                                            <td>Credit Card</td>
                                        </tr>
                                        <tr className="white-row">
                                            <td>Account Status</td>
                                            <td> Write Off</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <p className='lastline'> ⚠ Bank has initiated legal action</p>
                            <button type="button" onClick={handlePayFullAmount}>Pay Full Amount</button>
                            <button type="button" onClick={handlePayIn1Emis}>Pay in 1 EMIs</button>
                            <button className="lastbutton" type="button" onClick={handleSettlementApproval}>Pending for Settlement Approval</button>
                        </div>
                    ) : (
                        <p>Loading...</p>
                    )}
                </div>
            </div>

            {showModal && (
                <div className="modal">
                    <div className="modal-content" style={{ position: 'relative' }}>
                        <span className="close" onClick={handleCloseModal} style={{ position: 'absolute', top: '10px', right: '10px' }}>&times;</span>
                        <h2 style={{ textAlign: 'center', textDecorationLine: 'underline' }}>{popupContent.title}</h2>
                        {popupContent.title === 'Sorry' ? (
                            <>
                                <p style={{ textAlign: 'center' }}>{popupContent.message}</p>
                                <button className="button1" onClick={handleCloseModal} style={{ display: 'block', margin: '1vh auto' }}>Go Back</button>
                            </>
                        ) : (
                            <>
                                <table className="popuptable" style={{ margin: '0 auto' }}>
                                    <thead>
                                        <tr className="popuptr" style={{ textDecorationLine: 'underline' }}>
                                            <th style={{ textAlign: 'center' }}> Date</th>
                                            <th style={{ textAlign: 'center' }}>Amount</th>
                                        </tr>
                                    </thead>
                                    {popupContent.emiDetails && popupContent.emiDetails.length > 0 && (
                                        <tbody>
                                            {popupContent.emiDetails.map((emi, index) => (
                                                <tr key={index}>
                                                    <td style={{ textAlign: 'center' }}>{emi.date}</td>
                                                    {popupContent.title === 'Pending for Settlement Approval' ? (
                                                        <td style={{ textAlign: 'center' }}>
                                                            <input
                                                                type="text"
                                                                value={settlementAmount}
                                                                onChange={(e) => setSettlementAmount(e.target.value)}
                                                            />
                                                        </td>
                                                    ) : (
                                                        <td style={{ textAlign: 'center' }}>{emi.amount}</td>
                                                    )}
                                                </tr>
                                            ))}
                                        </tbody>
                                    )}
                                </table>
                                {popupContent.title === 'Pending for Settlement Approval' ? (
                                    <button className="button1" onClick={handleCheckForSettlement} style={{ display: 'block', margin: '1vh auto' }}>Check for Settlement</button>
                                ) : (
                                    <>
                                        {popupContent.title === 'Pay in 1 EMIs' ? (
                                            <button className="button1" onClick={handlePayNow} style={{ display: 'block', margin: '1vh auto' }}>Pay Now</button>
                                        ) : (
                                            <button className="button1" onClick={handlePayNow} style={{ display: 'block', margin: '1vh auto' }}>{popupContent.title === 'Congratulations!' ? 'Pay Now' : 'Go Back'}</button>
                                        )}
                                        {showofferText && popupContent.title === 'Congratulations!' && (
                                            <h6 style={{ textAlign: 'left' }}>*Offer valid only for next 7</h6>
                                        )}
                                        {popupContent.title === 'Pay in 1 EMIs' && (
                                            <h6 style={{ textAlign: 'left' }}>Interest will continue to occur on the unpaid outstanding amount until the<br /> total outstanding  amount is paid.<br /> *Offer valid only for next 7</h6>
                                        )}
                                    </>
                                )}
                            </>
                        )}
                    </div>
                </div>
            )}

            {showSecondPopup && (
                <div className="modal">
                    <div className="modal-content" style={{ position: 'relative' }}>
                        <span className="close" onClick={() => setShowSecondPopup(false)} style={{ position: 'absolute', top: '10px', right: '10px' }}>&times;</span>
                        <h2 style={{ textAlign: 'center', textDecorationLine: 'underline' }}>Congratulations!</h2>
                        <table className="popuptable" style={{ margin: '0 auto' }}>
                            <thead>
                                <tr className="popuptr" style={{ textDecorationLine: 'underline' }}>
                                    <th style={{ textAlign: 'center' }}> Date</th>
                                    <th style={{ textAlign: 'center' }}>Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={{ textAlign: 'center' }}>{popupContent.emiDetails[0].date}</td>
                                    <td style={{ textAlign: 'center' }}>{settlementAmount}</td>
                                </tr>
                            </tbody>
                        </table>
                        <button className="button1" onClick={handlePayNow} style={{ display: 'block', margin: '1vh auto' }}>Pay Now</button>
                    </div>
                </div>
            )}

        </>
    );
};

export default UserDetailsPage;

