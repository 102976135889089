import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import SendOTP from './pages/SendOTP';
// import VerifyOTP from './pages/VerifyOTP';
import UserDetailsPage from './pages/user_details';


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<SendOTP />} />

        {/* <Route path="/verify" element={<VerifyOTP />} /> */}
        <Route path="/user_details/:phoneNumber" element={<UserDetailsPage />} />
      </Routes>
    </Router>
  );
}

export default App;

