
// import React, { useState } from 'react';
// import axios from 'axios';
// import { useNavigate } from 'react-router-dom';
// import '../css/sendotp.css';
// import CCimage from '../images/CCimage.png';

// const SendOTPPage = () => {
//     const [phoneNumber, setPhoneNumber] = useState('');
//     const [otp, setOtp] = useState('');
//     const [error, setError] = useState('');
//     const [userFound, setUserFound] = useState(false);
//     const [otpSent, setOtpSent] = useState(false);
//     const navigate = useNavigate();

//     const handleVerifyOTP = async (e) => {
//         e.preventDefault();

//         try {
//             const response = await axios.post('http://localhost:8000/verify_otp/', { Phone: phoneNumber, otp });

//             console.log('OTP verification successful:', response.data);

//             setError('');
//             localStorage.setItem('phoneNumber', phoneNumber);
//             navigate(`/user_details/${phoneNumber}`);
//         } catch (error) {
//             console.error('Error verifying OTP:', error.response.data);
//             setError('Incorrect OTP. Please try again.');
//         }
//     };

//     const handleSubmitPhoneNumber = async (e) => {
//         e.preventDefault();

//         try {
//             const response = await axios.post('http://127.0.0.1:8000/send_otp/', { Phone: phoneNumber });

//             if (response.data.error) {
//                 console.error('Error sending OTP:', response.data.error);
//                 setError('User not found. Please enter a valid phone number.');
//                 setUserFound(false);
//                 setOtpSent(false);
//             } else {
//                 console.log('OTP sent successfully:', response.data);
//                 setError('');
//                 setUserFound(true);
//                 setOtpSent(true);
//             }
//         } catch (error) {
//             console.error('Error sending OTP:', error);
//             setError('Failed to send OTP. Please try again.');
//             setUserFound(false);
//             setOtpSent(false);
//         }
//     };

//     return (
//         <div className="page-container">
//             <div className="content-container">
//                 <img src={CCimage} alt="logo" className="logo" />
//                 <h2>{userFound && otpSent ? 'Enter OTP' : 'Log In'}</h2>
//                 {userFound && otpSent && (
//                     <h3 size="3xl" as="p" className="relative mt-[-5px]">
//                         To resolve your dues today
//                     </h3>
//                 )}
//                 {userFound && otpSent ? (
//                     <form onSubmit={handleVerifyOTP} className="form-container">
//                         <label htmlFor="otp">Enter OTP:</label>
//                         <input type="text" id="otp" value={otp} onChange={(e) => setOtp(e.target.value)} required />
//                         <button type="submit">Verify OTP</button>
//                     </form>
//                 ) : (
//                     <form onSubmit={handleSubmitPhoneNumber} className="form-container">
//                         <label htmlFor="phone">Please Enter Your Mobile Number:</label>
//                         <input type="text" id="phone" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} required />
//                         <button type="submit" disabled={userFound && otpSent}>Send OTP</button>
//                     </form>
//                 )}
//                 {error && <p className="error-message">{error}</p>}
//             </div>
//         </div>
//     );
// };

// export default SendOTPPage;

import React, { useState, } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../css/sendotp.css';
import CCimage from '../images/CCimage.png';
import herocorp from '../images/herocorp.png';
import { FaGlobe } from 'react-icons/fa';

const SendOTPPage = () => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [otp, setOtp] = useState('');
    const [error, setError] = useState('');
    const [userFound, setUserFound] = useState(false);
    const [otpSent, setOtpSent] = useState(false);
    const navigate = useNavigate();

    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

    const handleCheckboxChange = (event) => {
        setIsCheckboxChecked(event.target.checked);
    };






    const handleVerifyOTP = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post('https://cd.stefto.com/clearyourdues/verify_otp/', { Phone: phoneNumber, otp });

            // console.log('OTP verification successful:', response.data);
            console.log('OTP verification successful');
            setError('');
            localStorage.setItem('phoneNumber', phoneNumber);
            navigate(`/user_details/${phoneNumber}`);
        }catch (error) {
        if (error.response && error.response.data) {
            console.error('Error verifying OTP:', error.response.data);
        } else {
            console.error('Error verifying OTP:', error);
        }
        setError('Incorrect OTP. Please try again.');
    }
    };

    const handleSubmitPhoneNumber = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post('https://cd.stefto.com/clearyourdues/send_otp/', { Phone: phoneNumber });

            if (response.data.error) {
                console.error('Error sending OTP:', response.data.error);
                setError('User not found. Enter a valid phone number.');
                setUserFound(false);
                setOtpSent(false);
            } else {
                // console.log('OTP sent successfully');
                // console.log('OTP sent successfully:', response.data);
                setError('');
                setUserFound(true);
                setOtpSent(true);

            }
        } catch (error) {
            console.error('Error sending OTP:', error);
            setError('Failed to send OTP. Please try again.');
            setUserFound(false);
            setOtpSent(false);
        }
    };

    return (
        <div className="page-container">
            <div className="content-container">
                <div className="top-bar">
                    <img src={CCimage} alt="logo" className="logo" />
                    <div className="language-selector">
                        <FaGlobe className="globe-icon" />
                        <select className="language-dropdown">
                            <option value="en">English</option>
                            <option value="fr">Tamil</option>
                            <option value="fr">Hindi</option>
                        </select>
                    </div>
                </div>
                <h2>{userFound && otpSent ? 'Enter OTP' : 'Log In'}</h2>
                <h5 size="3xl" as="p" className="relative mt-[-5px]" style={{ textDecorationLine: 'underline' }}>
                    To resolve your dues today
                </h5>
                {userFound && otpSent ? (
                    <form onSubmit={handleVerifyOTP} className="form-container">
                        <label htmlFor="otp">Enter OTP:</label>
                        <input type="text" id="otp" value={otp} onChange={(e) => setOtp(e.target.value)} required />
                        <button className="verifybutton" type="submit">Verify OTP</button>
                    </form>
                ) : (
                    <form onSubmit={handleSubmitPhoneNumber} className="form-container">
                        {/* <label htmlFor="phone">Please Enter Your Mobile Number</label> */}
                        <h6 className="enternunber" htmlFor="phone"  >Please Enter Your Mobile Number</h6>
                        {/* <input type="text" placeholder='Mobile Number' id="phone" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} required /> */}
                        <div className="phoneinput" style={{ display: 'flex', alignItems: 'center' }}>
                            <span style={{ marginRight: '5px' }}>+91</span>
                            <input
                                type="text"
                                id="phone"
                                value={phoneNumber}
                                placeholder='Mobile Number'
                                onChange={(e) => setPhoneNumber(e.target.value)}
                                required
                            />
                        </div>
                        {/* <button type="submit" disabled={userFound && otpSent && !isCheckboxChecked} >Send OTP</button> */}


                        <label htmlFor="vehicle1" className="checkbox-label">
                            <input className="checkbox" type="checkbox" id="vehicle1" name="vehicle1" value="Bike" checked={isCheckboxChecked} onChange={handleCheckboxChange} />
                            <h6>I agree to the Terms & Conditions and Privacy Policy.</h6>
                        </label>
                        <button type="submit" disabled={!isCheckboxChecked}>Send OTP</button>



                    </form>
                )}
                {error && <p className="error-message">{error}</p>}
            </div>
        </div >
    );
};

export default SendOTPPage;
